import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
    window.addEventListener("scroll", function () {
      let propertylink = _this.element.querySelector('#property-link');
      if (window.scrollY > 10) {
        propertylink.classList.add('be-booking-link--slide-in-bottom');
        propertylink.classList.remove('opacity-0');
        if (window.scrollY >= 300) {
          propertylink.querySelector('.be-btn').classList.add('bg-primary-250', 'text-white');
          propertylink.querySelector('.be-btn').classList.remove('be-btn--outline-primary');
        }
      }
      else {
        propertylink.classList.remove('be-booking-link--slide-in-bottom');
        propertylink.querySelector('.be-btn').classList.remove('bg-primary-250', 'text-white');
        propertylink.querySelector('.be-btn').classList.add('be-btn--outline-primary');
        propertylink.classList.add('opacity-0');
      }
    });
  }

  switchLocale(e) {
    const localeToSet = e.currentTarget.dataset.locale.toLowerCase();
    if (currentLocale === localeToSet) return;

    const url = `/switch-locale?locale=${localeToSet}`
    const replacedUrl = location.href.replace(currentLocale, localeToSet);
    fetch(url, {
      method: 'post',
      headers: {
        'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").content
      }
    }).then(() => {
      history.replaceState(null, null, replacedUrl);
      location.reload();
    });
  }
}
