import {Controller} from "@hotwired/stimulus"
import './tw-elements-loader_controller';

export default class extends Controller {
  propertySort(event) {
     // TODO: BE need for infinite scroll
   /* let sortValue = event.currentTarget.dataset.value;
     fetch(`/properties/sort?value=${sortValue}`).then(response => {
      response.text().then(text => {
      });
     });*/
  }
}
