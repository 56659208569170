import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['lineClamp', 'linkText', 'teamItem'];
  lineClamp = this.lineClampTarget;
  linkText = this.linkTextTarget;

  readMore(evt) {
    let currentMode = evt.currentTarget.dataset.id;
    let readMoreText = evt.currentTarget.dataset.readMore;
    let readLessText = evt.currentTarget.dataset.readLess;
    this.teamItemTargets.forEach(el => {
      if (currentMode === 'readmore') {
        let lineClamp = el.querySelector('[data-team-more-target=lineClamp]');
        let btn = el.querySelector('[data-team-more-target=linkText]');
        const classLine = evt.currentTarget.dataset.class;
        lineClamp.classList.remove(classLine);
        btn.innerHTML = readLessText;
        btn.dataset.id = 'readless';
      } else {
        let lineClamp = el.querySelector('[data-team-more-target=lineClamp]');
        let btn = el.querySelector('[data-team-more-target=linkText]');
        const classLine = evt.currentTarget.dataset.class;
        lineClamp.classList.add(classLine);
        btn.innerHTML = readMoreText;
        btn.dataset.id = 'readmore';
      }

    });


  }
}
