import {Controller} from "@hotwired/stimulus";
import {Swiper} from 'swiper/bundle';

export default class extends Controller {

  connect() {
    let swiperWrapper = document.getElementById('pressCardSwipper');
    let slides = swiperWrapper?.querySelectorAll('.swiper-slide');
    let swiperOptions;

    swiperOptions = {
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
          mousewheel: true,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
    };
    const swiper = new Swiper('.press-card-swipper', swiperOptions);

    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (!regex.test(navigator.userAgent) && slides.length == 2) {
      swiperWrapper.classList.remove('swiper-wrapper');
      swiperWrapper.classList.add('flex');
      swiperWrapper.classList.add('justify-center');

      slides.forEach((slide) => {
        slide.classList.remove('swiper-slide');
      });
    }
  }
}
