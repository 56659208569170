import {Controller} from "@hotwired/stimulus";
import {Swiper} from 'swiper/bundle';


export default class extends Controller {
  connect() {
    const swiper = new Swiper(`.properties-swiper-${this.element.dataset.index}`, {
      direction: 'horizontal',
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });
  }

}
