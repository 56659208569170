import {Controller} from "@hotwired/stimulus";
import {Swiper} from 'swiper/bundle';

export default class extends Controller {
  connect() {
    const swiper = new Swiper('.swiper', {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 24,
      breakpoints: {
        320: {

          slidesPerView: 1,
          spaceBetween: 20,
          mousewheel: true
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      }
    });
  }
}
