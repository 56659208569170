import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['properties', 'beyoulWorks', 'aboutUs', 'canvas', 'seller']

  connect() {
    let locationPath = location.pathname
    if (locationPath.includes('properties')) {
      this.propertiesTargets.forEach((el) => {
        el.classList?.add('font-aventa_bold')
      });
    } else if (locationPath.includes('beyoul-works')) {
      this.beyoulWorksTargets.forEach((el) => {
        el.classList?.add('font-aventa_bold')
      });
    }  else if (locationPath.includes('home-seller')) {
      this.sellerTargets.forEach((el) => {
        el.classList?.add('font-aventa_bold')
      });
    } else if (locationPath.includes('about-us')) {
      this.aboutUsTargets.forEach((el) => {
        el.classList?.add('font-aventa_bold')
      });
    }
  }

  showMenu() {
    this.canvasTarget.classList.add('be-fade-in');
    if (this.canvasTarget.classList.contains('be-fade-out')) {
      this.canvasTarget.classList.remove('be-fade-out');
    }
  }

  hideMenu() {
    this.canvasTarget.classList.add('be-fade-out');
    if (this.canvasTarget.classList.contains('be-fade-in')) {
      this.canvasTarget.classList.remove('be-fade-in');
    }
  }

}