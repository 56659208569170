import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener('popstate', function (event) {
      const newLocale = window.currentLocale; // the new locale you want to use
      const currentUrl = window.location.href;
      const regex = /(\/\/[^\/]+\/)(\w{2})?(\/)?/; // match domain, optional locale, and optional trailing slash'

      const lastParam = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
      if (lastParam === '' || window.availableLocales.includes(lastParam)) {
        window.history.replaceState(null, '', currentUrl.replace(regex, `$1${newLocale}`));
      } else {
        window.history.replaceState(null, '', currentUrl.replace(regex, `$1${newLocale}/`));
      }

      location.reload();
    });
  }
}
