import {Controller} from "@hotwired/stimulus";
import './tw-elements-loader_controller';

export default class extends Controller {
  reloadAfterSwitch(event) {
    const newLocale = event.currentTarget.dataset.locale.toLowerCase()
    const currentUrl = window.location.href;
    const regex = /(\/\/[^\/]+\/)(\w{2})?(\/)?/;

    const lastParam = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    if (lastParam === '' || window.availableLocales.includes(lastParam)) {
      window.history.replaceState(null, '', currentUrl.replace(regex, `$1${newLocale}`));
    } else {
      window.history.replaceState(null, '', currentUrl.replace(regex, `$1${newLocale}/`));
    }
    location.reload();
  }
}
