import {Controller} from "@hotwired/stimulus";
import {Swiper} from 'swiper/bundle';

export default class extends Controller {
  connect() {
    const swiper = new Swiper('.be-steps-swiper', {
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 24,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      }
    });
  }
}
