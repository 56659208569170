import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['lineClamp', 'linkText'];
  lineClamp = this.lineClampTarget;
  linkText = this.linkTextTarget;

  connect() {
  }

  readMore(evt) {
    let currentText = evt.currentTarget.dataset.id;
    let readMoreText = evt.currentTarget.dataset.readMore;
    let readLessText = evt.currentTarget.dataset.readLess;
    const classLine = evt.currentTarget.dataset.class;
    const classArray = classLine.split(" ");

    if (currentText === 'readmore') {
      classArray.forEach(className => {
        this.lineClamp.classList.remove(className);
      });
      evt.currentTarget.innerHTML = readLessText;
      evt.currentTarget.setAttribute('data-id', 'readless');
    } else {
      classArray.forEach(className => {
        this.lineClamp.classList.add(className);
      });
      evt.currentTarget.innerHTML = readMoreText;
      evt.currentTarget.setAttribute('data-id', 'readmore');
    }

  }
}
