import { Controller } from '@hotwired/stimulus';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';

export default class extends Controller {
  connect() {
    const media = this.element.dataset.media;
    lightGallery(document.getElementById('lightgallery-' + media), {
      zoom: true,
      zoomFromOrigin: false,
      plugins: [lgThumbnail, lgFullscreen, lgZoom],
      speed: 0,
      licenseKey: '0000-0000-000-0000',
      selector: '.be-lightallery-item',
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: true,
      },
    });
  }

  openLightbox(evt) {
    document.getElementById('image_slide2').click();
  }
}
