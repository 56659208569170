import { application } from "./application";
import HelloController from "./hello_controller";
import CarouselController from "./carousel_controller";
import FacilitiesCarouselController from "./facilities-carousel_controller";
import PropertiesDetailCarouselController from "./properties-detail-carousel_controller";
import PropertiesImageLightboxController from "./properties-image-lightbox_controller";
import WindowLocationController from "./window-location_controller";
import ShowMoreController from "./show-more_controller";
import TeamMoreController from "./team-more_controller";
import PropertySortController from "./property-sort_controller";
import NavigationController from "./navigation_controller";
import PropertyLinkController from "./property-link_controller";
import StepsCarouselController from "./steps-carousel_controller";
import HistoryController from './history_controller';
import LanguageSwitcherController from './language-switcher_controller';
import PressCardCarouselController from './press-card-carousel_controller';
import DestinationsCarouselController from "./destinations-carousel_controller";

application.register('hello', HelloController);
application.register('carousel', CarouselController);
application.register('facilities-carousel', FacilitiesCarouselController);
application.register('language-switcher', LanguageSwitcherController);
application.register(
  'properties-detail-carousel',
  PropertiesDetailCarouselController
);
application.register(
  'properties-image-lightbox',
  PropertiesImageLightboxController
);
application.register('window-location', WindowLocationController);
application.register('show-more', ShowMoreController);
application.register('team-more', TeamMoreController);
application.register('property-sort', PropertySortController);
application.register('navigation', NavigationController);
application.register('property-link', PropertyLinkController);
application.register('steps-carousel', StepsCarouselController);
application.register('history', HistoryController);
application.register('press-card-carousel', PressCardCarouselController);
application.register('destinations-carousel', DestinationsCarouselController);
