import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

export default class extends Controller {
  connect() {
    const swiper = new Swiper(`.swiper-${this.element.dataset.index}`, {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
}
